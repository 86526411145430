
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import { Search } from "@element-plus/icons-vue";
import router from "@/router";

export default defineComponent({
  //name: "TopupFormModal",
  setup() {
    const store = useStore();
    const creditItemList = ref([
      "2000",
      "5000",
      "8000",
      "10000",
      "15000",
      "20000",
    ]);
    const bankItemList = {
      nameName: "",
      bankNumber: "",
      bankName: "",
      idCard: "",
      documentId: "",
      documentBank: "",
    };
    const formInput = reactive({ ...bankItemList });
    const amountTopup = ref("");
    const amountNote = ref("");
    const isTopUp = ref(false);
    const QrUrl = ref("");
    const referenceNumber = ref("");
    const isLoading = ref(false);
    const isCancel = ref(false);
    const isSuccess = ref(false);
    const errorName = ref("");
    const errorNumber = ref("");
    const errorBankname = ref("");
    const erroridCard = ref("");
    const errorDocId = ref("");
    const errorDocBankId = ref("");
    const errorDocBank = ref("");
    const errorFile = ref("");
    const errorRadio = ref("");
    const addImageForm = ref(null);
    const fileupload = ref("");
    const fileInputKey = ref(0);
    const Topup = ref(false);
    const lock = ref("");
    const lockinput = ref("");
    const errorNumberChange = ref("");
    const erroridCardChange = ref("");
    const img = ref("");
    let nextPage = ref(true);
    const defaultBank = ref("");
    const preview = ref("");
    const image = ref("");
    const createBankList = ref({});
    const imgbase64 = ref("");
    const checknumber = ref(false);
    const testfail = ref("Please select an amount from 2,000 to 50,000");
    function clickToSetAmount(value) {
      amountTopup.value = value;
    }
    const searchForm = computed(() => {
      return store.state.BillModule.filterBillListForm;
    });
    const bankList = computed(() => {
      return store.state.BankModule.get_bank;
    });
    const searchBank = computed(() => {
      return store.state.BankModule.filterBankListForm;
    });
    const get_name_bank = computed(() => {
      return store.state.BankModule.get_bank_name;
    });
    const get_approve_default = computed(() => {
      return store.state.BankModule.get_approve_default;
    });
    function handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64EncodedImage = reader.result;
        base64(base64EncodedImage);
      };
    }
    function handleFileUploadImg(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64EncodedImage = reader.result;
        base64Img(base64EncodedImage);
      };
    }
    function base64(item) {
      formInput.documentId = item;
    }
    function base64Img(item) {
      formInput.documentBank = item;
    }
    function checkValue() {
      if (/^\d+$/.test(formInput.nameName)) {
        errorName.value = "โปรดกรอกตัวอักษร";
      } else {
        errorName.value = "";
        lock.value = "success";
      }
    }
    function checkValueText() {
      lockinput.value = "";
      if (/^\d+$/.test(formInput.bankNumber)) {
        errorNumberChange.value = "";
        lockinput.value = "success";
      } else {
        errorNumberChange.value = "โปรดกรอกเฉพาะตัวเลข";
      }
    }
    function checkValueTextID() {
      lock.value = "";
      if (/^\d+$/.test(formInput.idCard)) {
        erroridCardChange.value = "";
        lock.value = "success";
      } else {
        erroridCardChange.value = "โปรดกรอกเฉพาะตัวเลข";
      }
    }
    function reset() {
      amountTopup.value = "";
      isTopUp.value = false;
      QrUrl.value = "";
      referenceNumber.value = "";
      isCancel.value = false;
      isLoading.value = false;
      isSuccess.value = false;
      Object.assign(formInput, bankItemList);
      errorName.value = "";
      errorNumber.value = "";
      errorBankname.value = "";
      erroridCard.value = "";
      errorDocId.value = "";
      errorDocBank.value = "";
      searchBank.value.bank_text = "";
      defaultBank.value = "";
      errorDocBankId.value = "";
      nextPage.value = true;
      formInput.documentId = "";
      formInput.documentBank = "";
      errorRadio.value = "";
      fileInputKey.value++;
      lockinput.value = "";
      lock.value = "";
    }
    async function amountTopupCheck() {
      if (parseFloat(amountTopup.value) < 2000) {
        amountTopup.value = "";
        checknumber.value = true;
      } else {
        checknumber.value = false;
      }
    }
    async function validate() {
      if (!formInput.nameName) {
        errorName.value = "กรอกข้อมูลให้ครบ";
      }
      if (!formInput.bankNumber) {
        errorNumber.value = "กรอกข้อมูลให้ครบ";
      }
      if (!formInput.bankName) {
        errorBankname.value = "กรอกข้อมูลให้ครบ";
      }
      if (!formInput.idCard || formInput.idCard.toString().length != 13) {
        erroridCard.value = "กรอกข้อมูลให้ครบ";
      }
      if (!formInput.documentId) {
        errorDocId.value = "กรอกข้อมูลให้ครบ";
      }
      if (!formInput.documentBank) {
        errorDocBank.value = "กรอกข้อมูลให้ครบ";
      }
      if (
        formInput.bankNumber.toString().length < 10 ||
        formInput.bankNumber.toString().length > 15
      ) {
        errorNumber.value = "กรอกจำนวนเลขให้ถูกต้อง";
      }
      if (!formInput.documentId) {
        errorDocBankId.value = "ใส่รูปเลข ID";
      }
      if (!formInput.documentBank) {
        errorDocBank.value = "ใส่รูปบัญชี";
      }
      if (
        !errorName.value &&
        !errorNumber.value &&
        !errorBankname.value &&
        !erroridCard.value &&
        !errorDocBankId.value &&
        !errorDocBank.value &&
        lockinput.value == "success" &&
        lock.value == "success"
      ) {
        console.log(formInput);
        isLoading.value = true;
        let result = await store.dispatch(
          "BankModule/" + Actions.TOPUP_CREATE_BANK,
          { formInput }
        );
        if (result == false) return;
        await store.dispatch(`BankModule/${Actions.FETCH_LIST_BANK}`);
        fileInputKey.value++;
        addImageForm.value = null;
        isLoading.value = false;
        Topup.value = true;
        nextPage.value = !nextPage.value;
      }
    }
    function onSubmit() {
      errorName.value = "";
      errorNumber.value = "";
      errorBankname.value = "";
      erroridCard.value = "";
      errorDocId.value = "";
      errorDocBank.value = "";
      errorDocBankId.value = "";
      validate();
    }
    async function topUp() {
      console.log(amountTopup.value);
      if (parseFloat(amountTopup.value) <= 0 || amountTopup.value == "") return;
      isLoading.value = true;
      console.log("Success");
      let result = await store.dispatch(
        "WalletModule/" + Actions.TOPUP_CREDIT_WALLET,
        amountTopup.value
      );
      isLoading.value = false;
      if (result == false) return;
      referenceNumber.value = result.referenceNo;
      QrUrl.value = result.url;
      isTopUp.value = true;
    }

    async function checkStatusTopup() {
      isLoading.value = true;
      let result = await store.dispatch(
        "WalletModule/" + Actions.CHECK_STATUS_TOPUP,
        referenceNumber.value
      );
      isLoading.value = false;
      if (result == false) return;
      isSuccess.value = true;
    }
    async function onSubmitRadio() {
      if (!defaultBank.value) {
        errorRadio.value = "set default bank";
      } else {
        isLoading.value = true;
        const result = await store.dispatch(
          `BankModule/${Actions.TOPUP_DEFAULT_BANK}`,
          defaultBank.value
        );
        defaultBank.value = "";
        await store.dispatch(`BankModule/${Actions.FETCH_LIST_BANK}`);
        isLoading.value = false;
        nextPage.value = !nextPage.value;
        if (result == false) return;
        isSuccess.value = true;
      }
    }
    const searchankList = computed(() => {
      return store.getters["BankModule/bankListDefault"].filter((val) => {
        return (
          val.bank_manager_bank.bank_name_en
            .toLowerCase()
            .includes(searchBank.value.bank_text_default.toLowerCase()) ||
          val.bank_manager_bank.bank_name_th
            .toLowerCase()
            .includes(searchBank.value.bank_text_default.toLowerCase()) ||
          val.bank_manager_customer_name
            .toLowerCase()
            .includes(searchBank.value.bank_text_default.toLowerCase()) ||
          val.bank_manager_customer_id_card
            .toLowerCase()
            .includes(searchBank.value.bank_text_default.toLowerCase())
        );
      });
    });
    return {
      creditItemList,
      amountTopup,
      clickToSetAmount,
      topUp,
      isTopUp,
      QrUrl,
      referenceNumber,
      isLoading,
      isCancel,
      reset,
      checkStatusTopup,
      isSuccess,
      amountNote,
      testfail,
      checknumber,
      amountTopupCheck,
      bankItemList,
      onSubmit,
      searchForm,
      formInput,
      errorFile,
      validate,
      errorName,
      errorNumber,
      errorBankname,
      erroridCard,
      errorDocId,
      errorDocBank,
      Topup,
      createBankList,
      img,
      bankList,
      defaultBank,
      onSubmitRadio,
      preview,
      image,
      searchBank,
      Search,
      handleFileUpload,
      imgbase64,
      base64,
      handleFileUploadImg,
      base64Img,
      searchankList,
      errorDocBankId,
      nextPage,
      errorRadio,
      get_name_bank,
      checkValue,
      checkValueText,
      addImageForm,
      fileupload,
      get_approve_default,
      fileInputKey,
      lock,
      lockinput,
      checkValueTextID,
      errorNumberChange,
      erroridCardChange,
    };
  },
});
